@font-face {
  font-family: 'font-bold';
  src: url(../public/assets/font/Aller_Bd.ttf);
}
@font-face {
  font-family: 'font-medium';
  src: url(../public/assets/font/AllerDisplay.ttf);
}
@font-face {
  font-family: 'font-regular';
  src: url(../public/assets/font/Aller_Rg.ttf);
}
*,p{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'font-regular', sans-serif;
  font-size: 16px;
  color: #000000;
  line-height: 1;
}
.navbar {
  background-color: #ffffff !important;
  padding: 32px;
}
.navbar-brand, .logo {
  color: #000000 !important;
  font-size: 28px;
  font-weight: bold;
  width: 272px;
}
.navbar-nav >li {
  margin: 0px 12px;
  text-align: center;
  border-bottom: 2px solid transparent;
}
.navbar-nav >li:hover >a {
  color: #F66F4D !important;
}
.nav-menu-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}
.img-flight {
  background: url(../public/assets/images/flights.svg);
}
.img-hotel {
  background: url(../public/assets/images/Hotel.svg);
}
.img-holidays {
  background: url(../public/assets/images/holiday.svg);
}
.img-cabs {
  background: url(../public/assets/images/cab.svg);
}
.img-invest {
  background: url(../public/assets/images/invest.svg);
}
.navbar-nav >li >a {
  color: #000000;
  padding: 0px 8px;
}
.navbar-nav >li:hover {
  border-bottom: 2px solid #F66F4D;
}
.navbar-nav >li >a:hover {
  color: #F66F4D;
}
.navbar-nav >li:hover .img-flight {
  background: url(../public/assets/images/flights_active.svg);
}
.navbar-nav >li:hover .img-hotel {
  background: url(../public/assets/images/hotel_active.svg);
}
.navbar-nav >li:hover .img-holidays {
  background: url(../public/assets/images/holiday_active.svg);
}
.navbar-nav >li:hover .img-cabs {
  background: url(../public/assets/images/cab_active.svg);
}
.navbar-nav >li:hover .img-invest {
  background: url(../public/assets/images/invest_active.svg);
}
.nav-secondary >li, .nav-secondary >li:hover {
  border-bottom: 0px;
  margin: 0 4px;
}
.section-block-hero {
  padding: 64px auto;
}
.section-block {
  padding: 64px auto;
}
.section-bg {
  background-color: #FEF3F1;
}
.booking-form {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  /* margin: 0px 24px; */
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.hero-form-title {
  font-family: 'font-bold';
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}
h1 {
  font-family: 'font-bold';
  font-size: 40px;
  line-height: 52px;
}
h2 {
  font-family: 'font-bold';
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 40px;
}
h3 {
  font-family: 'font-bold';
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4px;
}
p {
  font-family: 'font-regular';
  color: #484646;
  font-size: 16px;
  line-height: 26px;
}
p.text-dark {
  color: #000000 !important;
}
a {
  font-family: 'font-regular';
  font-size: 16px;
  line-height: 24px;
  color: #484646;
  text-decoration: none;
}
a:hover {
  color: #F66F4D;
  text-decoration: none;
}
.colour-title {
  color: #F66F4D;
  font-family: 'font-regular';
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 4px;
}
.hero-text {
  font-family: 'font-regular';
  font-size: 18px;
  line-height: 28px;
}
.form-select, .form-control {
  border: 1px solid #79747E;
  height: 40px;
  font-size: 16px;
  line-height: 22px;
  width: 13rem;
}
.form-select:focus, .form-control:focus {
  box-shadow: none;
  border-color: #79747E;
}
.form-addon, .form-addon:focus {
  border-right: 1px solid transparent;
}
.input-group-text {
  background-color: #ffffff;
  border: 1px solid #79747E;
  border-left: 1px solid transparent;
}
::-moz-input-placeholder {
  color: #49454F;
}
::-webkit-input-placeholder {
  color: #49454F;
}
::-ms-input-placeholder {
  color: #49454F;
}
::placeholder {
  color: #49454F;
}
.btn-primary {
  background-color: #e37878;
  padding: 16px 24px;
  border-radius: 56px;
  border: 1px solid #e37878;
}
.form-symbol {
  line-height: 28px;
}
.btn-primary:hover {
  background-color: #e75f3c;
  border: 1px solid #e75f3c;
}
.btn-secondary {
  color: #000000;
  background-color: #ffffff;
  padding: 16px 24px;
  border-radius: 56px;
  border: 1px solid #000000;
}
.btn-secondary:hover {
  color: #000000;
  background-color: #e9e9e9;
  border: 1px solid #000000;
}
.trending-destination {
  text-align: center;
}
.trending-destination >img {
  width: 130px !important;
  height: 130px;
  border-radius: 130px;
  margin: 0 auto;
}
.owl-carousel {
  position: relative;
}
.owl-nav {
  /* position: relative; */
  /* top: 30%;
  transform: translateY(-30%);
  z-index: 999;
  display: flex;
  justify-content: space-between;
  width: 100%; */
}
.owl-nav .owl-prev, .owl-nav .owl-next {
  position: absolute;
  top: 20%;
  transform: translateY(50%);
  z-index: 999;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #ffffff !important;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.owl-nav .owl-next {
  right: 0px;
}
.owl-nav .owl-prev > span, .owl-nav .owl-next > span {
  font-size: 24px;
}
.owl-nav .owl-dots {
  display: none;
}
.img-style {
  width: 100%;
  border-radius: 16px;
}
.rating {
  font-weight: 'font-regular';
  margin-top: 16px;
  color: #000000;
}
.rating >span {
  font-weight: 'font-medium';
}
.price {
  color: #000000;
  margin-top: 16px;
}
.app-section-title {
  margin-bottom: 16px;
}
.app-text {
  color: #000000;
  font-size: 20px;
  line-height: 28px;
  font-weight: 'font-regular';
  margin-bottom: 40px;
}
.get-app-text {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
  font-family: 'font-bold';
  margin-bottom: 16px;
}
.app-icons >a {
  margin-right: 24px;
}
.app-image {
  text-align: center;
}
.form-check-input:checked {
  background-color: #70a9ff;
  border-color: #70a9ff;
}
.tab-margin {
  margin-top: 40px;
}
.tab-title {
  color: #000000;
  font-size: 24px;
  line-height: 32px;
  font-family: 'font-bold';
  margin-bottom: 8px;
}
.nav-tabs {
  border-bottom: 1px solid #B2B2B2;
  text-align: center;
}
.nav-tabs .nav-item .nav-link {
  color: #484646;
  font-size: 16px;
  line-height: 24px;
  font-family: 'font-regular';
  border-bottom: 1px solid #B2B2B2;
}
.nav-tabs .nav-item .nav-link.active, .nav-tabs .nav-item .nav-link:hover {
  border: 1px solid transparent;
  border-bottom: 1px solid #F66F4D;
  color: #F66F4D;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.modal-header {
  padding-bottom: 18px;
} 
.login-wrapper input,
.register-wrapper input:not([type="checkbox"]) {
  height: 40px;
  width: 100%;
}
.login-wrapper button.btn,
.register-wrapper button.btn {
  padding: 10px 15px;
}
.react-responsive-modal-modal {
  padding: 2.2rem !important;
  border-radius: 20px;
}
.nav-link,
.navbar-brand {
  cursor: pointer;
}
/* The overall container of the toggle switch */
.toggle-switch {
  position: relative;
  width: 60px;
  height: 30px;
  margin-right: 12px;
}
.sell-borser {
  color: red;
  font-weight: bold;
  font-size: x-large;
}
.buy-borser {
  color: green;
  font-weight: bold;
  font-size: x-large;
}

.spinner-border-sm {
  width: 1rem;
  height: 2rem;
  padding-left: 25px;
}

.toggle-flex label {
  margin-right: 12px;
}

/* The hidden checkbox */
.toggle-checkbox {
  display: none;
}

/* The background track of the toggle */
.toggle-label {
  position: absolute;
  cursor: pointer;
  background-color: red;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s;
}

/* The knob of the toggle */
.toggle-label::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

/* Change background color and knob position when checked */
.toggle-checkbox:checked + .toggle-label {
  background-color: green;
}

.toggle-checkbox:checked + .toggle-label::before {
  transform: translateX(30px);
}

.toggle-flex {
  display: flex;
  justify-content: flex-end;
}

.me-auto {
  width: 100%;
}

.light-green-color {
  background-color: #00800008;
}

.light-red-color {
  background-color: #ff000008;
}

.green-color {
  background-color: green;
}

.red-color {
  background-color: red;
}

.green-txt {
  color: green;
  font-weight: bold;
}

.red-txt {
  color: red;
  font-weight: bold;
}

.tooltip-inner {
  white-space: normal;
  max-width: 200px;
}

.accordion-button:focus,
.form-check-input:focus {
  box-shadow: none;
}

.order-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: .125rem;
  font-weight: 500;
}

.hr-line {
  height: 10px;
}

.margin-auto {
  width: 90%;
  margin: auto;
}

.form-check-input {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.form-check-input[type=radio],
.form-check-input[type=checkbox] {
  margin-right: 10px;
  border-color: #70a9ff87;
}

.form-check-label {
  font-size: 19px;
}

.margin-left {
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;
}

.margin-left-input {
  margin-left: 15px;
}

.trash-icons {
  cursor: pointer;
}

.margin-bottom-lot{
  margin-bottom: 8px;
}

.margin-top-input-price {
  margin-top: 22px;
}

.margin-top-input-day {
  margin-top: 90px;
}

.margin-top-input-split{
  margin-top: 50px;
}

.split {
  font-size: initial;
}

.btn-block {
  padding: 8px 58px;
}

.btn-block.reset {
  padding: 8px 50px;
  font-weight: bold;
  letter-spacing: 2px;
}

.margin-left.bos {
  margin-left: 8px;
}

.margin-left.bos button {
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  border: 0;
}

.section-wrapper {
  min-height: calc(100vh - -10px); 
}

.height-btm {
  height: 160px;
}

.broker-api-key-input {
  width: 300px;
}


@media screen and (max-width: 992px) {
  .navbar-nav {
      display: inline-block;
  }
  .navbar-nav >li {
      margin-top: 16px;
      margin-bottom: 16px;
      float: left;
  }
  .navbar-nav.nav-secondary {
      display: inline-block !important;
      width: 100%;
  }
  .nav-secondary >li, .nav-secondary >li:hover {
      margin-top: 16px;
      margin-bottom: 16px;
  }
  .navbar-collapse {
      box-shadow: 0px 2px 4px #e9e9e9;
      position: absolute;
      top: 104px;
      left: 0;
      z-index: 100;
      display: block;
      width: 100%;
      background-color: #ffffff;
      padding: 0px 24px;
  }
}